import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function ExploreSecondHalf(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Najstarejši slovenski rudnik?</h2>
                                    <p className="description">Izkoriščanje rude na območju Sitarjevca sega v daljno preteklost. Arheološka
                                        najdišča v bližnji okolici kažejo na to, da so v Litiji rudarili že pred 4000 leti. Iz tega
                                        obdobja so bili namreč najdeni ostanki bivališč in žlindre na območju današnje OŠ
                                        Litija. Na hribu Sitarjevec (448 m) so bili najdeni ostanki naselbine iz mlajše železne
                                        dobe. Z vrha je prečudovit pogled na reko Savo, kjer je nekoč potekala pomembna
                                        prometna pot, idealna za razvoj rudarstva na tem področju, kar so izkoristili že
                                        Rimljani in začeli kopati tudi pod zemljo.</p>

                                    <p className="description">
                                        Rudnik je deloval že v srednjem veku. Iz arhivskih virov namreč vemo, da je imel v
                                        Šmartnem pri Litiji sedež rudarski sodnik za Dolenjsko, kar govori o velikem pomenu
                                        rudnika, saj je bilo Šmartno takrat le manjša vas. Ohranjen nagrobni spomenik
                                        Christofa Bruckerschmieda iz leta 1537, ki je danes shranjen v cerkvi sv. Martina v
                                        Šmartnem pri Litiji, predstavlja najverjetneje rudarskega sodnika in ne rudarskega
                                        mojstra, kot se je še do nedavnega trdilo.
                                    </p>

                                    <p className="description">
                                        V času, ko je na gradu Bogenšperk živel Janez Vajkard Valvasor, kjer je napisal tudi
                                        Slavo vojvodine Kranjske, je bil rudnik zaprt. Vzrok za zaprtje zaenkrat še ni znan.
                                        Valvasor omenja staro rudarjenje na podlagi najdenih velikih hald (nasipov jalovine iz
                                        rudnika). Zapisal je med drugim, da je bil rudnik nekdaj velik, a da je sedaj zapuščen.
                                        Rudnik se je ponovno odprl šele v zadnji četrtini 19. stoletja. Izkoriščali so različno
                                        rudo, predvsem svinčevo in živosrebrovo. Poleg te so kopali še rudo, ki je vsebovala
                                        cink, baker, železo in v zadnjem obdobju obratovanja predvsem barit.
                                    </p>

                                    <p className="description">
                                        V vsej zgodovini delovanja rudnika se je zamenjalo veliko lastnikov, rudarjenje pa je
                                        bilo večkrat prekinjeno. Februarja 1965 se je v Litiji sestala komisija v zvezi s
                                        prenehanjem obratovanja rudnika. Ugotovili so, da rudarske raziskave niso dale
                                        pričakovanih rezultatov. Nizka vsebina kovine v rudi ni več omogočala rentabilnega
                                        odkopavanja, tehnična opremljenost jame je bila slaba, rudna telesa so bila majhna
                                        in razsejana v prostoru, kar je onemogočalo posodobitev načina dela. K poslabšanju
                                        razmer so pripomogli tudi problemi s predelovalnimi napravami in pljučna obolenja
                                        rudarjev (silikoza). Komisija je zato predlagala, da se dela v jami ustavijo.
                                        Proizvodnja svinca in barita je životarila še do konca julija 1965, nato pa so začeli
                                        obrate zapirati.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb">
                                    <StaticImage
                                        src="../../assets/images/rudnik/zgodovina-rudnika-sitarjevec-vhod-v-glavni-rov.jpg"
                                        alt="Zgodovina Rudnika Sitarjevec vhod v glavni rov"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100 h-100"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="related-work text-center mt--30">
                                <div className="thumb h-100">
                                    <StaticImage
                                        src="../../assets/images/rudnik/zgodovina-rudnika-sitarjavca-2.jpg"
                                        alt="Skupinska slika pred rudnikom sitarjevec - zgodovina rudnika"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100 h-100"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*second*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-12 order-1 order-lg-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../assets/images/rudnik/netopirji-mali-podkovnjak.jpg"
                                    alt="Netopirji (Mali Podkovanjak) v Rudniku Sitarjevec Litija."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Mali Podkovnjak (Rhinolophus Hipposideeros)</h2>
                                    <p className="description">Netopirji so edini sesalci, ki lahko letijo. Na svetu jih je več kot 1000 vrst, v Sloveniji
                                        30. V rudniku biva zaščitena vrsta mali podkovnjak, ki je ime dobil po podkvasti obliki
                                        ust. So zelo majhni, tehtajo do 10 gramov in merijo od 3-5 cm. Posebni niso le zaradi
                                        letenja, temveč tudi zaradi načina orientacije v prostoru s pomočjo nam neslišnega
                                        ultrazvoka, ki jim pomaga tudi pri lovu in zaradi hibernacije – tako kot medvedi zimo
                                        prespijo.</p>

                                    <p className="description">Rudnik je že od nekdaj njihovo bivališče. Skozi loputo na vratih hodijo ponoči na lov
                                       za komarji in drugimi žuželkami ter tako skrbijo za ravnovesje v naravi. Proti jutru se
                                       vrnejo v jamo in dan prespijo v bolj mirnih in skritih kotičkih rudnika.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*third*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--3">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Topilnica in čebelarska pravda</h2>
                                    <p className="description">Leta 1880 so za potrebe taljenja izkopane rude iz Rudnika Sitarjevec na levem bregu reke
                                        Save zgradili topilnico, v kateri so talili predvsem svinčevo in živosrebrno rudo. Rudo so
                                        preko reke Save transportirali s tovorno žičnico, dolgo 364 m, kakor tudi z vozovi preko
                                        lesenega mostu, zgrajenega leta 1855. Topilnica je povzročala številne težave, saj je zaradi
                                        izpustov strupenih žveplenih plinov negativno vplivala na zdravje ljudi, živali in rastlinja. Kljub
                                        dodatnim in višjim dimnikom, ki jih je vodstvo zgradilo zaradi številnih pritožb nad
                                        onesnaževanjem, je v letu 1920 prišlo do množičnega pogina čebel.</p>

                                    <p className="description">Čebelarska pravda se imenuje boj litijskih čebelarjev proti Topilnici Litija po poginu čebel.
                                        Končna razsodba je bila po nekaj letih sprejeta na višjem sodišču v Zagrebu v korist
                                        čebelarjev. Topilnico v Litiji so zaprli in stroje preselili v Topusko na Hrvaškem. Čebelarska
                                        pravda (1921 – 1926) je bila prva uspešna ekološka pravda v Sloveniji.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../assets/images/rudnik/topilnica-in-cebelarska-pravda.jpeg"
                                    alt="Topilnica in čebelarska pravda."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*forth*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../assets/images/rudnik/litijski-srebrnik.png"
                                    alt="Rudnik Sitarjevec sodi med polimeniralna rudišča z izjemno mineraloško pestrostjo."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Srebrnik (Litijski tolar)</h2>
                                    <p className="description">Leta 1886 so v litijski topilnici uspešno pridobili tudi srebro, ki se v manjši količini
                                        pojavlja v svinčevi rudi. V takratno glavno državno kovnico na Dunaju so poslali
                                        3,6999 kg srebra, iz katerega so izdelali spominske srebrnike – litijske tolarje. Na
                                        prednji strani je upodobljena takratna topilnica, vlak, cerkev sv. Nikolaja, hrib
                                        Sitarjevec in rudniška žičnica do topilnice. Na drugi strani je naziv rudnika s
                                        simboličnim napisom »prvi srebrni pogled«.</p>

                                    <p className="description">Srebrnik je v obliki spominskega magnetka možno kupiti tudi pri nas.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ExploreSecondHalf;