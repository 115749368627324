import React from 'react';
import SEO from "../components/seo/Seo";
import Header from "./../components/headers/Header";
import Footer from "../components/layouts/Footer";
import ExploreFirstHalf from './../components/explore-mine/ExploreFirstHalf';
import ExploreSecondHalf from './../components/explore-mine/ExploreSecondHalf';
import Dictionary from "../components/explore-mine/Dictionary";
import ExploreHeader from "../components/explore-mine/ExploreHeader";
import CallToAction from "../components/layouts/CallToAction";
import ExploreDocuments from "../components/explore-mine/ExploreDocuments";

const IndexPage = () => (
    <>
        <SEO title="Razišči zanimivosti in posebnosti litijskega podzemlja" description="Rudnik Sitarjevec sodi med polimeniralna rudišča z izjemno mineraloško pestrostjo. Sodi med najbogatejša najdišča različnih mineralov v evropskem in svetovnem merilu. V njem se pojavlja več kot šestdeset različnih mineralov, med katerimi so najpomembnejši rudni minerali galenit, sfalerit, cinabarit, halkopirit in barit" />
        <Header />
        <ExploreHeader/>
        <ExploreFirstHalf/>
        <ExploreSecondHalf/>
        <Dictionary/>
        <ExploreDocuments/>
        <CallToAction/>
        <Footer/>
    </>
);


export default IndexPage