import React from 'react';

const dictonary2 = [
    {word: "FOKSL", meaning: "Lesni klin"},
    {word: "FORT KAHEL", meaning: "Kovinski žičnik"},
    {word: "MAŠINCA", meaning: "Stroj za streljanje, miniranje"},
    {word: "LAMPA", meaning: "Rudarska osebna svetilka"},
    {word: "PELIGAN", meaning: "Ojačana lesena tesarka"},
    {word: "ŠAJBA", meaning: "Osebna tekoča jamska številka"},
    {word: "ŠAHT", meaning: "Izvozni zračni prehodni jašek"},
    {word: "ŠALA", meaning: "Kletka v jašku"},
    {word: "FIRENK", meaning: "Vodilo kletke v jašku"},
    {word: "ŠPRAHROR", meaning: "Cevodod za sporazumevanje"},
    {word: "ŠUFECA", meaning: "Krpa za ovijanje nog"},
    {word: "ŠUSMOJSTER", meaning: "Strelec za miniranje v rovu"},
    {word: "FALA", meaning: "Zadnji krajec debla, deska"},
    {word: "ŠIHT", meaning: "Osemurni delovnik"},
    {word: "ŠTAJGER", meaning: "Nadzornik, delovodja"},
    {word: "HAJER", meaning: "Prvopisani kopač"},
    {word: "LEERHAJER", meaning: "Pomočnik kopača"},
    {word: "LAUFAR", meaning: "Začetnik oz. nekvalificirani rudar"},
    {word: "OBERŠTAJGER", meaning: "Poslovodja"},
    {word: "CAJH", meaning: "Orodje"},
    {word: "HERC", meaning: "Lopata za premog"},
    {word: "KRAMP", meaning: "Kopača"},
    {word: "HINKO", meaning: "Odkopno kladivo"},
    {word: "PORKHAMER", meaning: "Odkopni vrtalni stroj"},
    {word: "KROCA", meaning: "Velika motika"},
    {word: "ŠTEMPEL", meaning: "Podporna železna ali lesena stojka"},
    {word: "RIGEL", meaning: "Podstropnik"},
    {word: "UNTERCUH", meaning: "Podstropnik križišča"},
    {word: "KVEL", meaning: "Lesena razpoka"},
    {word: "ŠPRUDEL", meaning: "Komad deske"},
    {word: "FORTNA", meaning: "Jamska lestev"},
    {word: "ŠTREKNA", meaning: "Izkop rova, tunel"},
    {word: "GEZENK", meaning: "Vpadni poševni rov"},
    {word: "AVPRZUH", meaning: "Rušenje stropa zza izkopom"},
    {word: "LEGNT", meaning: "Jalovina"},
    {word: "MAŠINHAUS", meaning: "Izvozna strojnica"},
    {word: "URLAUB", meaning: "Dopust"},
];

function Dictionary(props) {
    return (
        <div>
            <div className="about-wrapper pb--120 pt--120 bg_color--3 text-center">
                <div className="container">
                    <div className="row row--20 align-items-center">

                        <div className="col-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Knapovski slovar</h2>
                                    <p className="description">Knapovski jezik se je oblikoval tudi v sitarjevških rudniških jamah.</p>
                                </div>

                                <div className="row">
                                    { dictonary2.map(item => (
                                        <div className="col-md-3 col-6" key={item.word}>
                                            <div className="about-us-list">
                                                <h5 className="title">{item.word}</h5>
                                                <p className="w-100 text-center pr-0">{item.meaning}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dictionary;