import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import PdfIcon from "./PdfIcon";

function DocumentItem({documentItem}) {

    const [showDownloadHelperText, setShowDownloadHelperText] = useState(false);

    return (
        <div className="row document-item-container">
            <div className="document-item col-12 col-md-10 col-lg-9 col-xl-8">
                <div className="document-icon is-clickable"
                     onMouseOver={() => setShowDownloadHelperText(true)}
                     onMouseLeave={() => setShowDownloadHelperText(false)}
                >
                    <a target="_blank" href={documentItem.downloadFile} download>
                        <PdfIcon/>


                    </a>

                </div>
                <div className="content">
                    <a target="_blank" href={documentItem.downloadFile} download
                       onMouseOver={() => setShowDownloadHelperText(true)}
                       onMouseLeave={() => setShowDownloadHelperText(false)}
                    >
                        {documentItem.title}
                        <span className={`download-helper-text d-none d-lg-inline-block ${showDownloadHelperText ? 'active ' : ''}`}>Klikni za nalaganje</span>
                    </a>
                    <div className="description">{documentItem.description}</div>
                </div>
            </div>
        </div>
    );
}

export default DocumentItem;