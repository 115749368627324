import React from 'react';
import DocumentItem from "./DocumentItem";
import MiningHeritagePdf from './../../assets/downloadables/mining-heritage-in-slovenia.pdf';
import MineralFest2018Pdf from './../../assets/downloadables/mineral-fest-2018.pdf';
import SimpozijRudnikSitarjevecPdf from './../../assets/downloadables/simpozij-rudnik-sitarjevec.pdf';

const documents = [
    {
        title: "Rudarska dediščina kot Slovenska turistična atrakcija",
        description: "Rudnik Sitarjevec Litija eden od strebrov Dediščine rudarjenja v Sloveniji: Geološki zavod Slovenije je na razstavi v Kanadi predstavil Dediščino rudarjenja v Sloveniji. Najvidnejši strokovnjaki s področja poznavanja geološke zgradbe ozemlja Slovenije so za predstavitev izbrali tudi Rudnik Sitarjevec Litija.",
        downloadFile: MiningHeritagePdf
    },
    {
        title: "MineralFest Ljubljana 2018",
        description: "Ugledni slovenski strokovnjaki, ki že desetletja raziskujejo skrivnosti Rudnika Sitarjevec, so se srečali na strokovnem simpoziju v Litiji. Zbornik daje vpogled v vsebine, ki so predmet znanstvenega raziskovanja in najnovejše ugotovitve raziskovalcev.",
        downloadFile: MineralFest2018Pdf
    },
    {
        title: "1. Strokovni simpozij o Rudniku Sitarjevec",
        description: "Rudnik Sitarejvec Litija redno predstavljamo na specializiranih sejmih. Leta 2018 smo bili posebni gosti sejma mineralov, fosilov in nakita Mineralfest v Ljubljani.",
        downloadFile: SimpozijRudnikSitarjevecPdf
    }
];

function ExploreDocuments(props) {
    return (
        <div>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-12 col-md-12 col-xl-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Gradiva za nadaljnje branje</h2>
                                </div>
                            </div>
                        </div>

                        { documents.map(documentItem => (
                            <DocumentItem key={documentItem.title} documentItem={documentItem} />
                        )) }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreDocuments;