import React from 'react';
import {StaticImage} from "gatsby-plugin-image";

function ExploreFirstHalf(props) {
    return (
        <>
            <div className="about-wrapper ptb--120 bg_color--1">
                <div className="container">

                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-12 col-md-12 col-xl-5 order-1 mt-4 order-xl-0">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/sestdeset-60-razlicnih-vrst-mineralov.jpg"
                                    alt="Rudnik Sitarjevec sodi med polimeniralna rudišča z izjemno mineraloško pestrostjo."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-xl-7 order-0 order-xl-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Šestdeset različnih vrst mineralov</h2>
                                    <p className="description">Rudnik Sitarjevec sodi med polimeniralna rudišča z izjemno mineraloško pestrostjo. Sodi
                                        med najbogatejša najdišča različnih mineralov v evropskem in svetovnem merilu. V njem se
                                        pojavlja več kot šestdeset različnih mineralov, med katerimi so najpomembnejši rudni
                                        minerali galenit, sfalerit, cinabarit, halkopirit in barit.</p>
                                    <p className="description">V svetovno mineraloško dediščino so kot
                                        največji kristali tedanjega časa vpisani kristali cinabarita, ki merijo tudi do 6 cm, in skupki
                                        kristalov cerusita, veliki do 10 cm in več. Mineraloško redkost v svetovnem merilu
                                        predstavljajo črvički samorodnega svinca. Za brušenje nakita je odlična hematitno-jaspisna
                                        ruda, poldragi kamen, ki navduši s svojo rdečo in sivo barvo.</p>

                                    <p className="description">V rudniku so bila bogata
                                        nahajališča svinčeve in živosrebrove rude, ter rude ki so vsebovale cink, baker železo in
                                        barit. Rudnik Sitarjevec je sodil med največja nahajališča svinčeve rude v evropskem merilu.
                                        Ena najbolj atraktivnih lastnosti mineralov rudnika Sitarjevec je sijaj v celi paleti barv, ki
                                        nastane zaradi lomljenja svetlobe na njihovi površini. Sijaj nekaterih si lahko ogledate v
                                        Izvoznem rovu rudnika.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*second*/}
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">

                    {/*First */}
                    <div className="row align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Limonitni kapniki</h2>
                                    <p className="description">Prepoznavnost rudnika Sitarjevec v širšem prostoru odlikujejo izjemne limonitne kapniške
                                        strukture kot so stalagmiti, stalaktiti, kapniške zavese, kapniški špageti, ki so edinstveni v
                                        evropskem prostoru. Z veliko hitrostjo rastejo v opuščenih rudniških rovih. Ob ugodnih
                                        pogojih, kot so konstanten dotok z minerali obogatenih jamskih vod, temperaturi, vlagi in
                                        uravnoteženi mikroflori, kapniki zrastejo tudi do 5 cm na leto.</p>
                                    <p className="description">Barvitost in slikovitost rudniških
                                        rovov dopolnjuje rumeno limonitno blato, ki je odličen naravni pigment. Rudnik Sitarjevec
                                        Litija je edini znani prostor rastočih limonitnih kapnikov v Srednji Evropi. Limonitne kapniške
                                        strukture lahko občudujete v Glavnem rovu.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-4">
                                <StaticImage
                                    src="../../assets/images/rudnik/limonitni-kapniki.jpg"
                                    alt="Limonitni kapniki v Rudniku Sitarjevec so sedaj na voljo na ogled."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*third*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">

                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-5 col-md-12 col-xl-5 order-1 order-lg-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../assets/images/rudnik/izjemna-barvitost-rudnika-v-litiji-2021.jpg"
                                    alt="Izjemna barvitost v notrajnosti rudnika je ena izmed posebnosti Rudnika v Litiji."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-xl-7 order-0 order-lg-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Izjemna barvitost rudnika</h2>
                                    <p className="description">Rudnik Sitarjevec je izrazito barvit. Tople rumene barve železovega pigmenta naravni oker
                                        so opazne po celem rudniku, v izvoznem rovu ob straneh in po stropu, v glavnem rovu pa
                                        tudi po dnu v obliki rudniškega blata. Odličen pigment je primeren za slikanje in barvanje
                                        tekstila, kar si obiskovalec lahko ogleda na razstavi v izvoznem rovu.</p>

                                    <p className="description">Poleg okre sta v
                                        Sitarjevcu za slikarske namene uporabna še rdeči hematit in beli barit. Sitarjevške barve
                                        ustvarjajo posebno vzdušje, kar so umetniki izrazili s svetlobnim performansom neobičajnih
                                        svetil, kresničk, ki so jih obesili pod strop rudniškega jaška v Izvoznem rovu.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*forth*/}
            <div className="about-wrapper ptb--120 bg_color--3">
                <div className="container">

                    <div className="row row--35 align-items-center text-center text-lg-left">

                        <div className="col-lg-7 col-md-12 col-xl-7">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Gorotvorni procesi in tektonska drsa</h2>
                                    <p className="description">Litijsko rudno polje s Sitarjevcem se razteza v nizu nagubanih kamnin, ki so nastale zaradi
                                        velikih pritiskov gorotvornih procesov. Tektonsko dogajanje je povzročilo, da so se
                                        posamezni bloki dvigali ali spuščali, skladi kamnin nagubali in drugod počili, ali povzročili
                                        številne tektonske prelomnice. Tako so nastale tektonske drse, ki so kot zrcalo zglajena
                                        površina prelomne ploskve. Kamnine današnjih Posavskih gub so nastale ob ekvatorju, kar
                                        je dokaz o potovanju kontinentov in kako dolgo pot so kamnine prepotovale v 300 milijonih
                                        letih. Po dolgem potovanju jih lahko občudujemo v Rudniku Sitarjevec Litija.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-xl-5">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../assets/images/rudnik/gorotvorni-procesi.jpg"
                                    alt="Tektonsko dogajanje v okolici rudnika Sitarjevec Litija je povzročilo, da so se posamezni bloki dvigali ali spuščali in povzročili številne tektonske prelomnice."
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/*fifth*/}
            <div className="about-wrapper pb--120 pt--120 bg_color--1">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left ">
                        <div className="col-xl-5 col-12 order-1 order-xl-0">
                            <div className="thumbnail mt-5">
                                <StaticImage
                                    src="../../assets/images/rudnik/interpretacija-paleookolja.jpg"
                                    alt="Rekonstrukcija in interpretacija paleokolja v litijskem rudniku"
                                    placeholder="blurred"
                                    imgClassName="img-fluid"
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div className="col-xl-7 col-12 order-0 order-xl-1">
                            <div className="p-2 p-lg-4">
                                <div className="section-title">
                                    <h2 className="title">Rekonstrukcija in interpretacija paleokolja</h2>
                                    <p className="description">V dolgih 300 milijonih letih so zaradi premikanja litosferskih plošč kamnine z območja
                                        ekvatorja skupaj z rastlinskimi fosili pripotovale tudi na prostor Sitarjevca. Na osnovi fosilov,
                                        ki so bili najdeni tudi na širšem litijskem območju danes vemo, da je bilo tedaj rastlinje
                                        bistveno drugačno od današnjega. Bujne gozdove so sestavljale do 40 m visoke drevesaste
                                        praprotnice in prve golosemenke.</p>

                                    <p className="description">V močvirnih gozdovih so gospodarile številne velike
                                        dvoživke, med njimi orjaški kačji pastir, Meganeura, z razponom kril do 75 cm.
                                        Skulpture rastlinskih debel v napol zasutem Izvoznem rovu prikazujejo skrivnosten
                                        navidezno pravljičen svet, ki je podobno kot slikovna rekonstrukcija, skrben posnetek
                                        znanstveno raziskanega živega sveta ob koncu starega zemeljskega veka.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ExploreFirstHalf;